import axios from "axios";
import { message } from "antd";

export default class Service {
  //   static Server_Base_URL =
  //    process.env.NODE_ENV === "test" //"production"
  //      ? process.env.REACT_APP_API_URL
  // //     : "https://wms.elsnerit.com";
  //     : "http://localhost:8789";
  //    : process.env.NODE_ENV === "development" ?  "http://localhost:8789" : "https://wms.elsnerit.com"; 


// static Server_Base_URL = "https://freedomlogistics-wms.com";
// static Server_Base_URL = "http://localhost:8789";
  static API_URL ="https://wms-test.elsnerdev.co/api"
    // process.env.NODE_ENV === "production"
    //   ? process.env.REACT_APP_API_URL + "/api"
    //   : `${this.Server_Base_URL}/api`;

  static API_Call_Counter = 0;
  static incre_API_Call_Counter = () => this.API_Call_Counter++;
  static decre_API_Call_Counter = () =>
  (this.API_Call_Counter =
    this.API_Call_Counter > 0 ? this.API_Call_Counter - 1 : 0);

  static error_message = "Something went wrong!";
  static error_message_key = "error_message_key";

  static message_containner = [];
  static add_message = (text) => {
    var index = this.message_containner.findIndex((x) => x === text);
    // here you can check specific property for an object whether it exist in your array or not
    if (index === -1) {
      this.message_containner.push(text);
    }
    return index;
  };
  static remove_message = (message) =>
  (this.message_containner = this.message_containner.filter(
    (m) => m !== message
  ));
  static messageError = (msg) => {
    const index = this.add_message(msg);
    if (index === -1) {
      message.error(msg).then(() => {
        this.remove_message(msg);
      });
    }
  };

  static postMethod = "POST";
  static getMethod = "GET";
  static putMethod = "PUT";
  static deleteMethod = "DELETE";
  static headers = {
    accept: "application/json",
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "strict-origin-when-cross-origin": "*",
  };

  //dashboard
  static userCount = "/dashboard/totalUsers";
  static siteCount = "/dashboard/totalSites";
  static templateCount = "/dashboard/totalPermissiontemplate";

  //Auth Module
  static login = "/auth/login";
  static editAdmin = "/admin/editadminuserprofile";
  static refreshToken = "/auth/refreshToken";
  static userById = "/users/userById";
  static forgotPassword = "/auth/forgotPassword";
  static resetPassword = "/auth/resetPassword";
  static changePassword = "/auth/updatePassword";
  static setPassword = "/auth/setPassword";
  static userChangePassword = "/auth/DownlineuserchangePassword";

  //users
  static userList = "/users/userList";
  static editUserProfile = "/users/edituser";
  static userRoleList = "/roles/rolesDropdownList";
  static userTierList = "/tier/TierDropdownList";
  static userPermissionList = "/permission_templates/permission_templatesDropdownlist";
  static createUser = "/users/createUser";
  static deleteUser = "/users/deleteuser";
  static siteDropdownEdit = "/sites/EditUsersitesDropdownList";

  //activity
  static activityList = "/activities/activitiesList";

  //permission
  static permissionList = "/permission_templates/permission_templateslist";
  static addPermission = "/permission_templates/addpermission_templates";
  static editPermission = "/permission_templates/editpermission_templates";
  static permissionById = "/permission_templates/permissiontemplateById";
  static deletePermission = "/permission_templates/deletepermission_templates";

  //sites
  static siteList = "/sites/siteList";
  static addSite = "/sites/addsites";
  static siteById = "/sites/siteById";
  static updateSite = "/sites/editsites";
  static deleteSite = "/sites/deletesites";
  static lockUnlockSite = "/sites/lockedsites";
  static locklockSite = "/sites/unlockedsites"
  static archiveSite = "/sites/archivesites";
  static archivedSitesList = "/sites/archivedsitesList";
  static deletedSitesList = "/sites/deletedsitesList";
  static deleteArchivedSite = "/sites/deleteArchivesites";
  static TrackChangesList = '/sites/TrackChangesList';
  static reviveDelSite = '/sites/revivedeletesites';
  //site type
  static createSiteType = "/site_type/addSiteType";
  static SiteTypeList = "/site_type/siteTypeList";
  static siteTypeById = "/site_type/siteTypeById";
  static deletesiteType = '/site_type/deletesiteType';
  static siteTypeDropDown = "/site_type/SiteTypeDropdownList";
  static editSiteType = '/site_type/editSiteType';
  static associatedSites = '/site_type/associatedSites';

  //Managing Companies
  static addManagingcompany = '/managing_company/addManaging_company';
  static ManagingCompanyList = '/managing_company/ManagingCompanyList';
  static ManagingCompanyById = '/managing_company/ManagingCompanyById';
  static editManagingCompany = '/managing_company/editManagingCompany';
  static deleteManagingCompany = '/managing_company/deleteManagingCompany';
  static ManagingCompanyDropdownList = '/managing_company/ManagingCompanyDropdownList';
  static AssociatedusersModal = '/managing_company/associatedusers';
  static AssociatedUsersDetails = '/managing_company/associateduserswithmanagingcompany';
  static AssociatedSitesDetails = '/managing_company/associatedSiteswithmanagingcompany';

  //pre-arrival site Team
  static setUpSitesList = "/siteteam/siteteamList";
  static addSiteTeam = "/siteteam/addsiteteam";
  static getSiteTeamById = "/siteteam/siteteamById";
  static editSiteTeam = "/siteteam/editsiteteam";
  static usersDropdownSiteTeam = "/users/userDropdownList";

  //site tier2
  static siteDropDown = "/sites/sitesDropdownList";
  static siteManagementId = "/sites/sitemangementById";

  //storage
  //storage area type
  static addStorageAreatype = '/storage_area_type/addStorageAreatype';
  static StorageAreatypeList = "/storage_area_type/StorageAreatypeList";
  static deleteStorageAreatype = "/storage_area_type/deleteStorageAreatype";
  static editStorageAreatype = "/storage_area_type/editStorageAreatype";
  static StorageAreatypeById = "/storage_area_type/StorageAreatypeById";
  static StorageAreatypeDropdownList = "/storage_area_type/StorageAreatypeDropdownList";

  //storage area
  static addStorageArea = "/storage_area/addStorageArea";
  static editStorageArea = "/storage_area/editStorageArea";
  static StorageAreaById = "/storage_area/StorageAreaById";
  static StorageAreaList = "/storage_area/StorageAreaList";
  static archiveStorageArea = "/storage_area/archiveStorageArea";
  static deleteStorageArea = "/storage_area/deleteStorageArea";
  static exportStorageArea = "/storage_area/exportStorageArea";
  static archivedStorageAreaList = "/storage_area/ArchiveStorageAreaList";
  static storageAreaDropDown = "/storage_area/StorageAreaDropdownList";
  static exportArchiveStorageArea = "/storage_area/exportArchivedStorageArea";
  static storageUnitCount = "/storage_area/StorageUnitCount";
  static ItemsCounts = '/storage_area/ItemsCounts';


  //storage Unit
  static addStorageUnit = "/storage_unit/addStorageunit";
  static StorageUnitList = "/storage_unit/StorageUnitList";
  static FilterStorageUnitList = "/storage_unit/FilterStorageUnitList"
  static exportStorageUnitList = "/storage_unit/exportStorageunit";
  static StorageUnitById = "/storage_unit/StorageUnitById";
  static StorageLocationUnitById = "/storage_unit/StorageLocationUnitById";
  static editStorageUnit = "/storage_unit/editStorageunit";
  static archiveStorageUnit = "/storage_unit/archiveStorageunit";
  static archivedStorageUnitList = "/storage_unit/ArchivedStorageUnitList";
  static reviveStorageUnit = "/storage_unit/reviveStorageunit";
  static exportArchivedStorageUnitList = "/storage_unit/exportArchivedStorageunit";
  static printBarcodeByUnitId = "/storage_unit/PrintbarcodeUnitById";
  static printBarcodeByLocationId = "/storage_unit/PrintbarcodeLocationById";


  //floor collection
  static addFloorCollection = "/floor_collection/addFloorCollection";
  static floorCollectionList = "/floor_collection/FloorCollectionList";
  static floorCollectionById = "/floor_collection/FloorCollectionById";
  static editFloorCollection = "/floor_collection/editFloorCollection";
  static exportFloorCollection = "/floor_collection/exportFloorCollection";

  //BOM
  static importBOM = "/bom_file/importbomfile";
  static BOMUploadList = "/bom_file/bomfileList";
  static BOMSampleFile = "/bom_file/downlaodFormat";
  static archiveBOMFile = "/bom_file/archivebomfile";
  static reviveBOMFile = "/bom_file/revivebomfile";
  static AllBOMList = "/bom/AllBomList";
  static ParticularIdBOMList = "/bom/BomList";
  static addBom = "/bom/addBom";
  static getBomDataById = "/bom/BomById";
  static editBOM = "/bom/editBom";
  static appendBOM = "/bom_file/appendbomfile";
  static replaceBOM = "/bom_file/replacebomfile";
  static archivedBOMList = "/bom_file/ArchivebomfileList";
  static downloadBOMUploadedFile = "/bom/exportBomByfile";
  static downloadAllBOMFiles = "/bom/exportBomallfile";
  static archiveAllBOMItems = "/bom/ArchiveallItems";
  static archiveLineItems = "/bom/AchiveBomList";
  static downloadArchiveBOMFile = "/bom/exportArchivefile";
  static deleteBOMItems = "/bom/softdeleteItems";
  static getAllBOMidsList = "/bom/AllBomidsList";
  static getAllArchiveBOMidsList = "/bom/ArchiveBomidsList";
  static reviveSelectedItems = "/bom/ReviveallItems";
  static exportBomFileDataItems = "/bom/exportBomByfile";
  static exportArchivedLineItems = "/bom/exportArchivefile";
  static filterBOMItems = "/bom/FilterBomList";
  static FilterAchiveBomList = "/bom/FilterAchiveBomList";

  //PO
  static importPO = "/po_file/importpofile";
  static POUploadList = "/po_file/pofileList";
  static appendPO = "/po_file/appendpofile";
  static replacePO = "/po_file/replacepofile";
  static addPO = "/po/addpo";
  static allPOItems = "/po/AllpoList";
  static exportAllPOItems = "/po/exportpoItems";
  static getPOById = "/po/poById";
  static EditPOItem = "/po/editpo";
  static deletePO = "/po/deletepoItems";
  static POFileItems = "/po/poList";
  static exportPOFileItems = "/po/exportpoByfile";
  static filterPOItems = "/po/FilterpoList";
  static archivePOFile = "/po_file/archivepofile";
  static archivedPOList = "/po_file/ArchivepofileList";
  static revivePOFile = "/po_file/revivepofile";
  static exportArchivePOFile = "/po/exportArchivefile";
  static archivedPOLineItems = "/po/AchivepoList";
  static archiveAllPO = "/po/ArchiveallItems";
  static getAllPOidsList = "/po/AllpoidsList";
  static archivePOAllids = "/po/ArchivepoidsList";
  static revivePOItems = "/po/ReviveallItems";
  static FilterAchivepoList = "/po/FilterAchivepoList";

  //inventory

  static addpoInventory = "/inventory/addpoInventory";
  static FilterpoListInventory = "/inventory/FilterpoListInventory";
  static AllpoListInventory = "/inventory/AllpoListInventory";
  static showReceivedItems = "/inventory/ShowReceived_itemList";
  static changeItemStatus = "/inventory/ChangepoItemstatus";
  static PrintbarcodeLineItem = "/inventory/PrintbarcodeLineItem";
  static PrintbarcodeAllLineItems = "/inventory/PrintbarcodeAllLineItems";
  static PrintbarcodeOneforAll = "/inventory/PrintbarcodeOneforAll";
  static changeItemstsCount = "/inventory/ChangedamagedItemstatus";
  static exportReceivedPO = "/inventory/exportreceivedpo";
  static CheckreceivedItem = "/inventory/CheckreceivedItem";
  //incentory-stow
  static showReceiveLineItemsList = "/inventory/stow/ShowReceivedLineItemList";
  static downloadReceivedItems = "/inventory/stow/exportreccivedItems";
  static deleteStowReceivedItem = "/inventory/stow/DeleteMultipalRecievedItem";
  static getAllIdsForDel = "/inventory/stow/AllItemidsList";
  static filterDropDown = "/inventory/stow/FiltersDropdownList";
  static storageAreaDropDownList = "/inventory/stow/StorageAreaDropdownList";
  static StorageUnitTypeDropdownList = "/inventory/stow/StorageUnitTypeDropdownList";
  static StorageUnitIDdropdownList = "/inventory/stow/StorageUnitIDDropdownList";
  static StorageLocationIdDropdown = "/inventory/stow/StorageUnitLocationDropdownList";
  static assignLocation = "/inventory/stow/Assignlocation";
  static assignLocationToMultiple = "/inventory/stow/AssignMultiplelocation";
  static scannedItemsIds = "/inventory/stow/ShowScannedItemsList";
  static Assignscannedlocation = "/inventory/stow/Assignscannedlocation";
  static cleardataoncancel = '/inventory/stow/cleardataoncancel';
  //inventory-transfer
  static TrasferFiltersDropdownList = "/inventory/transfer/TrasferFiltersDropdownList";
  static AllStowedidsList = "/inventory/transfer/AllStowedidsList";
  static stowItemsList = "/inventory/transfer/ShowStowedItems";
  static downloadTransferItems = "/inventory/transfer/exportStowedItems";
  static TransferMultiplelocation = "/inventory/transfer/TransferMultiplelocation";
  static ScannedStowItems = "/inventory/transfer/ShowScannedStowedItemsList";
  static Transferscannedlocation = "/inventory/transfer/Transferscannedlocation";
  static transferIndividual = "/inventory/transfer/Transferlocation/";
  static Checklocation = "/inventory/Checklocation";
  static showtransferhistory = "/inventory/transfer/showtransferhistory";
  //inventory - deploy
  static DeployFiltersDropdownList = '/inventory/Deploy/DeployFiltersDropdownList';
  static ShowStowedandtransferItems = '/inventory/Deploy/ShowStowedandtransferItems';
  static exportStowedandtransferItems = '/inventory/Deploy/exportStowedandtransferItems';
  static allStowedandtransferItemsids = '/inventory/deploy/allStowedandtransferItemsids';
  static DeploySingleItem = '/inventory/Deploy/DeployItem';
  static DeployMultipleItem = '/inventory/deploy/DeployMultipleItem';
  static DeployedItemsList = '/inventory/deploy/ShowDeployedItems';
  static exportDeployedItems = '/inventory/Deploy/exportDeployedItems';
  static filterDeployedItems = '/inventory/deploy/filterDeployedItems';//view
  //inventory - manage
  static ManageFiltersDropdownList = '/inventory/Manage/ManageFiltersDropdownList';
  static ShowManageItems = '/inventory/Manage/ShowManageItems';
  static exportManageItems = '/inventory/Manage/exportManageItems';
  static DeleteItem = '/inventory/Manage/DeleteItem';
  static getManageItemByID = '/inventory/Manage/ItemById';
  static EdiitManageItem = '/inventory/Manage/EditItem';

  //dashboard - tier2
  static percentagereceiveditems = '/dashboard/percentagereceiveditems';
  static totaluniqueitems = '/dashboard/totaluniqueitems';
  static totaluniquereceiveditems = "/dashboard/totaluniquereceiveditems";
  static percentageuniquereceiveditems = "/dashboard/percentageuniquereceiveditems";
  static totalreceiveditems = "/dashboard/totalreceiveditems";
  static totalitemsInBom = "/dashboard/totalitems";

  //site inventory report
  static FilterSiteInventoryReport = "/reports/FilterSiteinvetoryreportList";
  static siteInventoryList = "/reports/SiteinvetoryreportList";
  static downloadSiteInventoryReport = "/reports/ExportSiteinvetoryreportList";
  static percentSiteInventory = "/reports/PercentageSiteinvetoryreportList";

  //all items reports
  static FilterAllItemsOnSite = "/reports/FilterAllItemsOnSite";
  static AllItemsOnSite = "/reports/AllItemsOnSite";
  static downloadAllItemsReport = "/reports/exportAllItemsOnSite";
  static PercentageAllItemsOnSite = "/reports/PercentageAllItemsOnSite";
  //discrepancy report 
  static FilterDiscrepancy = "/reports/FilterDiscrepancy";
  static DiscrepancyReportList = "/reports/DiscrepancyReportList";
  static downloadDiscrepancyReport = "/reports/ExportDiscrepancyReportList";
  //module report
  static filterModule = "/reports/FilterModuleCompletenessReport";
  static ModuleCompletenessList = "/reports/ModuleCompletenessReportList";
  static downloadModuleReport = "/reports/ExportModuleCompletenessReportList";
  static percentageModuleReport = "/reports/PercentageModuleCompletenessReportList";
  //expected delivery report
  static FilterExpectedDelivery = "/reports/FilterExpectedDeliveryReportList";
  static ExpectedDeliveryList = "/reports/ExpectedDeliveryReportList";
  static downloadDeliveryReport = "/reports/ExportExpectedDeliveryReportList";
  //order completeness
  static filterOrderCompleteness = "/reports/FilterOrderCompletenessReportList";
  static OrderCompletenessList = "/reports/OrderCompletenessReportList";
  static downloadOrderCompleteness = "/reports/ExportOrderCompletenessReportList";
  static percentageOrderCompleteness = "/reports/PercentageOrderCompletenessReportList";
  //bom qty vs inventory
  static FilterBomQtyVsInventory = "/reports/FilterBOMvsInventoryReportList";
  static BomQtyVsInventoryList = "/reports/BOMvsInventoryReportList";
  static downloadBomVsInventory = "/reports/ExportBOMvsInventoryReportList";

  //close-site
  static archivedclosesitesList = '/sites/archivedclosesitesList';
  static CloseSitesDropdownList = '/sites/CloseSitesDropdownList';
  static CloseSiteById = '/sites/CloseSiteById';
  static archivesitesforclose = '/sites/archivesitesforclose';
  static revivesitesforclose = '/sites/revivesitesforclose';
  static checkInventory = '/sites/checkinventory';



  static dateFormat(date) {
    var monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var timeStr = date,
      temp = timeStr.split("T")[0].split("-").reverse(),
      newFormat;

    temp[0] = temp.splice(1, 1, temp[0])[0];
    temp[0] = parseInt(temp[0]);
    temp[0] = monthNames[temp[0]];
    var tem = temp[0]
    temp[0] = temp[1]
    temp[1] = tem;
    // console.log(temp);
    newFormat = temp.join(" ");
    if (newFormat.charAt(0) === "0") {
      newFormat = newFormat.slice(1);
    }
    return newFormat;
  }


  static async makeAPICall({
    props,
    methodName,
    api_url,
    body,
    params,
    options,
  }) {
    api_url = this.API_URL + api_url;

    //request interceptor to add the auth token header to requests
    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers = {
            accept: "application/json",
            "content-type": "application/json",
            authorization: "Bearer " + accessToken,
            platform: "web-admin",
            ...options
          };
        } else {
          config.headers = {
            accept: "application/json",
            "content-type": "application/json",
            platform: "web-admin",
            ...options
          };
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    //response interceptor to refresh token on receiving token expired error
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem("refreshToken");
        if (
          refreshToken &&
          error?.response?.status === 401 &&
          !originalRequest._retry
        ) {
          if (originalRequest.url.includes("/refreshToken")) {
            return Promise.reject(error);
          }
          originalRequest._retry = true;
          try {
            const url = Service.API_URL + Service.refreshToken;
            const response = await axios.post(url, {
              refreshToken: refreshToken,
            });
            if (response.status === 200 && response.data.authToken) {
              localStorage.setItem(
                "accessToken",
                response.data.authToken.accessToken
              );
              localStorage.setItem(
                "refreshToken",
                response.data.authToken.refreshToken
              );
              console.log("Access token refreshed!");
              const res = await axios(originalRequest);
              return res;
            } else {
              console.log("Refresh Token Error", error);
              return Promise.reject(response);
            }
          } catch (e) {
            return Promise.reject(e);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    if (methodName === this.getMethod) {
      if (params) {
        api_url = api_url + "?" + params;
      }
      try {
        const response = await axios.get(api_url);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.postMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.post(api_url, body, options);
        return response;
      } catch (error) {

        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.putMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.put(api_url, body, options);
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
    if (methodName === this.deleteMethod) {
      if (params) {
        api_url = api_url + "/" + params;
      }
      try {
        const response = await axios.delete(api_url, { data: body });
        return response;
      } catch (error) {
        if (props && error.response && error.response.status === 401) {
          this.logOut(props);
        }
        return error.response;
      }
    }
  }

  static logOut(props) {
    props.logOutHandler();
    props.history.push("/login");
  }

  static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
