import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

//import CustomScrollbars from "util/CustomScrollbars";
//import languageData from "./languageData";
import { toggleCollapsedSideNav } from "../../appRedux/actions";
//import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import UserProfile from "../Sidebar/UserProfile";
//import AppNotification from "../../components/AppNotification";
//import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";


import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const Topbar = () => {

  const { navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

 
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require("assets/images/w-logo.png")} />
        </Link>
      <ul className="gx-header-notifications gx-ml-auto">
        {width >= TAB_SIZE ?
          <Auxiliary>
            <li className="gx-user-nav"><UserProfile /></li>
          </Auxiliary>
          :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo /></li>
          </Auxiliary>
        }
      </ul>
    </Header>
  );
};

export default Topbar;
