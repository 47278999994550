import React, { useEffect, useState } from "react";
import { Button, Form, Input, Typography, Row, Col, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
// import InfoView from "components/InfoView";
import loginlogo from '../assets/images/login-logo.svg'
import box1 from '../assets/images/box1.png'
import box2 from '../assets/images/box2.png'
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';

const { Title } = Typography;

const SignIn = (props) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const user_status = useSelector(({ auth }) => auth.userStatus);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  
  useEffect(()=>{
    if (user_status === 2) {
      setVisible(true);
    }
  },[user_status])


  const onFinish = async(values) => {
    //console.log("finish", values)
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

 

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-form">
          <Row gutter={[16, 16]}>
            <Col md={12} sm={24} xs={24}>
              <div className="logo-divlogin">
                <img className="login-logo" alt="logo" src={loginlogo} />
              </div>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form
                initialValues={{ remember: true }}
                //name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item>
                  <Title level={5}>Login</Title>
                </Form.Item>
                <Form.Item
                  //initialValue="superadmin@gmail.com"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email address'
                    },
                    // {
                    //   validator: validator
                    // }
                  ]}
                  name="email">
                  <Input placeholder="Email" prefix={<MailOutlined />} />
                  {/* <MailOutlined /> */}
                </Form.Item>
                <Form.Item
                  //initialValue="superadmin@123"
                  rules={[{
                    required: true,
                    message: 'Please enter your password'
                  }]}
                  name="password">
                  <Input type="password" placeholder='Password' prefix={<UnlockOutlined />} />
                  {/* Input.Password */}
                </Form.Item>

                <Form.Item className="link-right">
                  <Link to="/forgotpassword">Trouble Logging In?</Link>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  {/* <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                      id="app.userAuth.signUp"/></Link> */}
                </Form.Item>
                {/* <p className="error"><InfoView /></p> */}
              </Form>
            </Col>

            <Modal
              footer={null}
              visible={visible}
              onCancel={() => setVisible(false)}
            >
              <p style={{ color: 'red' }}>Your permission has been revoked please contact to admin.</p>
            </Modal>

          </Row>
        </div>
      </div>
      <div className="box-login">
        <img src={box1} alt="box1" className="box-1" />
        <img src={box2} alt="box2" className="box-2" />
      </div>
    </div>
  );
};

export default SignIn;
