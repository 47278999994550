import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { NavLink,  useHistory } from "react-router-dom";
import Service from "../../service";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
//import UserProfile from "./UserProfile";
//import AppsNavigation from "./AppsNavigation";
import {
  TeamOutlined, RiseOutlined, FileDoneOutlined, CloseSquareOutlined,
  HeatMapOutlined, DatabaseOutlined, AppstoreOutlined
} from '@ant-design/icons';
import { BiLock } from "react-icons/bi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiOutlineFund, AiOutlineDesktop } from "react-icons/ai";
import { RiStackLine, RiSettings5Line, RiAdminLine } from "react-icons/ri";
import { MdStorage } from "react-icons/md";

import {
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
//import IntlMessages from "../../util/IntlMessages";
import { getUserTier } from "../../appRedux/actions";
import { useDispatch, useSelector } from 'react-redux';


const { SubMenu } = Menu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const [sidebar, setSidebar] = useState('');

  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebartier, siteDetails } = useSelector(({ common }) => common);
  const permissions_allowed = JSON.parse(localStorage.getItem("permissions_allowed"));
  // console.log('permissions_allowed', permissions_allowed);
  let history = useHistory();

  const id = authUser._id;
  const dispatch = useDispatch();
  let { themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);
  //when set to tier2 page/component should be - site-management //site-selection

  // for menu one toggle menu
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5'];
  const [openKeys, setOpenKeys] = React.useState(['sub1']);
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };


  // for menu two toggle menu
  const rootSubmenuKeys2 = ['sub11', 'sub12', 'sub13', 'sub14', 'sub15'];
  const [openKeys2, setOpenKeys2] = React.useState(['sub11']);
  const onOpenChange2 = keys => {
    const latestOpenKey1 = keys.find(key => openKeys2.indexOf(key) === -1);
    if (rootSubmenuKeys2.indexOf(latestOpenKey1) === -1) {
      setOpenKeys2(keys);
    } else {
      setOpenKeys2(latestOpenKey1 ? [latestOpenKey1] : []);
    }
  };

  //alert(sidebartier);
  //const sidebartier=localStorage.getItem("sidebartier");
  useEffect(() => {
    //get tier and set
    const sidebarCheck = localStorage.getItem("sidebartier");

    if (!sidebarCheck) {
      getUserTierAPI();
    } else if (sidebarCheck == 'reader') {
      setSidebar('tier1');
    } else {
      setSidebar(sidebarCheck)
    }
  }, []);

  useEffect(() => {

    if (sidebartier === 'tier3') {
      //localStorage.setItem("sidebartier", sidebartier);
      setSidebar(sidebartier);
    } else if (sidebartier == 'reader') {
      setSidebar('tier1');
    }
  }, [sidebartier]);

  useEffect(() => {
    const arr = ["dashboard", "usersList", "sitesList", "permissionList", "siteTypeList", "managingComapanyList", "AdminProfile"];
    const arr2 = ["site-selection", "close-site", "site-inventory-report", "AdminProfile2", "archive-close-site"];
    //console.log("pathname.split('/')", pathname.split('/')[1]);

    if (arr.includes(pathname.split('/')[1]) || pathname === '/') {
      dispatch(getUserTier("tier1"));
      setSidebar("tier1");
    } else if (arr2.includes(pathname.split('/')[1])) {
      dispatch(getUserTier("tier2"));
      setSidebar("tier2");
    } else {
      dispatch(getUserTier("tier3"));
      setSidebar("tier3");
    }

    if (pathname == '/manage-items' && !permissions_allowed.includes('Perform Manage Actions')) {
      history.push("/site-management");
    }

  }, [pathname]);


  const getUserTierAPI = async () => {
    try {
      //dispatch(showAuthLoader());
      const params = `/${id}`;
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.userById + params,
      });
      //dispatch(hideAuthLoader());
      //console.log(response.data.data);
      if (response.data && response.data.data) {
        let tier = response.data.data.tier_id.tier_type;
        // if (tier === 'systemAdmin') {
        //   setSystemAdSidebar(tier);
        //   tier = 'tier1';
        // }
        dispatch(getUserTier(tier));
        setSidebar(tier);
        //localStorage.setItem("sidebartier", tier);
      }

    } catch (error) {
      //dispatch(hideAuthLoader());
      console.log(error);
    }
  }



  // const getNoHeaderClass = (navStyle) => {
  //   if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
  //     return "gx-no-header-notifications";
  //   }
  //   return "";
  // };


  const selectedKeys = pathname.substr(1);
  let defaultOpenKeys = pathname.split('/');
  defaultOpenKeys = defaultOpenKeys[defaultOpenKeys.length - 1]

  return (
    <>

      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar sidebar-scroll">

          {sidebar === 'tier1' || sidebar === 'systemAdmin' ? (
            <>
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                //selectedKeys={[selectedKeys]}
                //defaultSelectedKeys={['usersList']}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline"
                className="sidebar-menu"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
              >
                {permissions_allowed?.includes('Manage Users') &&
                  <SubMenu key="sub1" icon={<TeamOutlined />} title="USERS" >
                    {permissions_allowed?.includes('View All Users') &&
                      <Menu.Item key="usersList"
                        className={defaultOpenKeys === 'usersList' ? 'active' : null}
                      >
                        <NavLink to="/usersList">
                          <span style={defaultOpenKeys === 'usersList' ? { color: "orange" } : null}>Manage Users</span>
                        </NavLink>
                      </Menu.Item>}

                    {permissions_allowed?.includes('Add Users') &&
                      <Menu.Item key="create-user"
                        className={defaultOpenKeys === 'create-user' ? 'active' : null}
                      >
                        <NavLink to="/usersList/create-user">
                          <span style={defaultOpenKeys === 'create-user' ? { color: "orange" } : null}>Create Users</span>
                        </NavLink>
                      </Menu.Item>}
                  </SubMenu>}
                {authUser?.tier_id?.tier_type === 'systemAdmin' || authUser?.tier_id?.tier_type === 'reader' ?
                  <SubMenu key="sub2" icon={<BiLock />} title="PERMISSIONS">
                    <Menu.Item key="permission-temp">
                      <NavLink to="/permissionList"
                        className={defaultOpenKeys === 'permissionList' ? 'active' : null}
                      >
                        <span style={defaultOpenKeys === 'permissionList' ? { color: "orange" } : null}>Permission Templates</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="create-permission">
                      <NavLink to="/permissionList/create-permission"
                        className={defaultOpenKeys === 'create-permission' ? 'active' : null}
                      >
                        <span style={defaultOpenKeys === 'create-permission' ? { color: "orange" } : null}>Create Template</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                  : null
                }

                <SubMenu key="sub3" icon={<HiOutlineOfficeBuilding />} title="SITES">
                  {permissions_allowed?.includes('View Site Information') &&
                    <Menu.Item key="setup-site">
                      <NavLink
                        //activeClassName="active" 
                        to="/sitesList"
                        className={defaultOpenKeys === 'sitesList' ? 'active' : null}
                      >
                        <span>Manage Existing Sites</span>
                      </NavLink>
                    </Menu.Item>
                  }
                  {permissions_allowed?.includes('Create Site') &&
                    <Menu.Item key="create-site">
                      <NavLink
                        //activeClassName="active" 
                        to="/sitesList/create-site"
                        className={defaultOpenKeys === 'create-site' ? 'active' : null}
                      >
                        <span>Create Site</span>
                      </NavLink>
                    </Menu.Item>}
                </SubMenu>
                {authUser.tier_id.tier_type !== 'tier2' ?
                  <>
                    <SubMenu key="sub4" icon={<AiOutlineFund />} title="SITE TYPE">
                      <Menu.Item key="Site-Type-list">
                        <NavLink
                          //activeClassName="active" 
                          to="/siteTypeList"
                          className={defaultOpenKeys === 'siteTypeList' ? 'active' : null}
                        >
                          <span>Manage Site Types</span>
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="Create-Site-Type">
                        <NavLink
                          //activeClassName="active"
                          to="/siteTypeList/createSiteType"
                          className={defaultOpenKeys === 'createSiteType' ? 'active' : null}
                        >
                          <span>Create Site Type</span>
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    {/* {authUser?.tier_id?.tier_type == 'systemAdmin' || authUser?.tier_id?.tier_type === 'reader' ? */}
                    <SubMenu key="sub5" icon={<RiStackLine />} title="MANAGING COMPANIES">
                      <Menu.Item key="Managing-Companies">
                        <NavLink
                          //activeClassName="active" 
                          to="/managingComapanyList"
                          className={defaultOpenKeys === 'managingComapanyList' ? 'active' : null}
                        >
                          <span>Managing Companies List</span>
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="Create-Managing-Company">
                        <NavLink
                          //activeClassName="active" 
                          to="/managingComapanyList/addCompany"
                          className={defaultOpenKeys === 'addCompany' ? 'active' : null}
                        >
                          <span>Create Managing Company</span>
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  </>
                  : null}

                <Menu.Item key="admin" icon={<RiSettings5Line />}>
                  <NavLink
                    activeClassName="active"
                    to="/site-selection"
                    onClick={() => {
                      setSidebar("tier2");
                      dispatch(getUserTier('tier2'));
                      //localStorage.setItem("sidebartier", JSON.stringify('tier2'));
                    }}
                  >
                    <span>GO TO SITE MANAGEMENT</span>
                  </NavLink>
                </Menu.Item>
              </Menu>
            </>
          ) : sidebar === 'tier2' ? (
            <>
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline"
                className="management-menu"
              >
                <Menu.ItemGroup key="siteMgmt" title="Site Management">
                  <Menu.Item key="another-site" icon={<AiOutlineDesktop />}>
                    <NavLink activeClassName="active" to="/site-selection">
                      <span>Select a Site</span>
                    </NavLink>
                  </Menu.Item>
                  {/* {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' ? */}
                    {authUser.tier_id.tier_type != 'tier2' ? 
                    <Menu.Item key="close-site" icon={<CloseSquareOutlined />}>
                      <NavLink activeClassName="active" to="/close-site">
                        <span>Close Site</span>
                      </NavLink>
                    </Menu.Item>
                    : null}
                </Menu.ItemGroup>
                {permissions_allowed?.includes('View Site Reports') &&
                  <Menu.Item key="siteInvenReport" icon={<FileDoneOutlined />}>
                    <NavLink activeClassName="active" to="/site-inventory-report">
                      <span>Reports</span>
                    </NavLink>
                  </Menu.Item>}
                {/* {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' || authUser.tier_id.tier_type === 'reader' ? */}
                {authUser.tier_id.tier_type != 'tier2' ?
                  <Menu.Item key="admin" onClick={() => {
                    setSidebar('tier1');
                    //localStorage.setItem("sidebartier", 'tier1'); 
                  }}
                    icon={<RiAdminLine />}>
                    <NavLink activeClassName="active" to="/dashboard">
                      <span>Back to Admin</span>{/* set tier1 */}
                    </NavLink>
                  </Menu.Item> : null
                }
              </Menu>

            </>
          ) : (
            <>
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline"
                className="management-menu"
                openKeys={openKeys2}
                onOpenChange={onOpenChange2}
              >
                <Menu.Item key="site name">
                  <h5 style={{ color: 'white', whiteSpace: 'normal', cursor: 'default' }}>
                    Welcome to {siteDetails?.site_name_code} Management
                  </h5>
                </Menu.Item>

                <Menu.Item key="site-dashboard" icon={<AppstoreOutlined />}>
                  <NavLink activeClassName="active" to="/site-management">
                    <span>Site Dashboard</span>
                  </NavLink>
                </Menu.Item>

                {/* {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' ? */}
                {authUser.tier_id.tier_type != 'tier2' &&
                  permissions_allowed?.includes('Manage BOM') ?
                  <SubMenu key="sub11" title="BOM" icon={<HeatMapOutlined />}>
                    <Menu.Item key="uploadBOM">
                      <NavLink activeClassName="active" to="/upload-BOM">
                        <span>Upload BOM</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="manageBOM">
                      <NavLink activeClassName="active" to="/BOM-management">
                        <span>BOM Management</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="archivedBOM">
                      <NavLink activeClassName="active" to="/archived-BOM">
                        <span>Archived BOM</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu> : null}

                {/* {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' ? */}
                {authUser.tier_id.tier_type !== 'tier2' ?
                  <SubMenu key="sub12" title="STORAGE" icon={<MdStorage />}>
                    <Menu.Item key="setup-storage">
                      <NavLink
                        to="/manage-storageArea"
                        className={defaultOpenKeys === 'manage-storageArea' ? 'active' : null}
                      >
                        <span>Manage Storage Area</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="storageArea-type">
                      <NavLink
                        to="/storageArea-type-information"
                        className={defaultOpenKeys === 'storageArea-type-information' ? 'active' : null}
                      >
                        <span>Manage Storage Area Type</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="storage-unit">
                      <NavLink activeClassName="active" to="/storageUnitList">
                        <span>Manage Storage Unit</span>
                      </NavLink>
                    </Menu.Item>

                    {/*<Menu.Item key="floorCollection">
                    <NavLink
                      to="/storageArea-type-information/Floor-collection-List"
                      className={defaultOpenKeys == 'Floor-collection-List' ? 'active' : null}
                    >
                      <span>Manage Floor Collection</span>
                    </NavLink>
                   */}
                  </SubMenu> : null}

                {/* {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' ? */}
                {authUser.tier_id.tier_type != 'tier2' &&
                  permissions_allowed?.includes('Purchase Order Data') ?
                  <SubMenu key="sub13" title="PURCHASE ORDER DATA" icon={<DatabaseOutlined />}>
                    <Menu.Item key="upload-poData">
                      <NavLink activeClassName="active" to="/Upload-PO">
                        <span>Upload PO</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="manage-poData">
                      <NavLink activeClassName="active" to="/PO-List">
                        <span> Manage PO Data</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="archivedPO">
                      <NavLink activeClassName="active" to="/archived-PO">
                        <span>Archived PO</span>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu> : null}

                <SubMenu key="sub14" title="INVENTORY MANAGEMENT" icon={<RiseOutlined />}>
                  {permissions_allowed?.includes('Perform Receive Actions') &&
                    <Menu.Item key="receiveItems">
                      <NavLink activeClassName="active" to="/received-items">
                        <span>Receive Items</span>
                      </NavLink>
                    </Menu.Item>}
                  {permissions_allowed?.includes('Perform Stow Actions') &&
                    <Menu.Item key="stowItems">
                      <NavLink activeClassName="active" to="/stow-items">
                        <span>Stow Items</span>
                      </NavLink>
                    </Menu.Item>}
                  {permissions_allowed?.includes('Perform Transfer Actions') &&
                    <Menu.Item key="transferItems">
                      <NavLink activeClassName="active" to="/transfer-items">
                        <span>Transfer Items</span>
                      </NavLink>
                    </Menu.Item>}
                  {permissions_allowed?.includes('Perform Deploy Actions') &&
                    <Menu.Item key="deployItems">
                      <NavLink activeClassName="active" to="/deploy-items">
                        <span>Deploy Items</span>
                      </NavLink>
                    </Menu.Item>}
                  {authUser.tier_id.tier_type != 'tier2' &&
                    permissions_allowed?.includes('Perform Manage Actions') ?
                    <Menu.Item key="manageItems">
                      <NavLink activeClassName="active" to="/manage-items">
                        <span>Manage Items</span>
                      </NavLink>
                    </Menu.Item> : null}
                </SubMenu>

                {permissions_allowed?.includes('View  Inventory Reports') &&
                  <SubMenu key="sub15" title="INVENTORY REPORTING" icon={<FileDoneOutlined />}>

                    <Menu.Item key="allReport">
                      <NavLink activeClassName="active" to="/all-items-report">
                        <span>All Item Report</span>
                      </NavLink>
                    </Menu.Item>
                    {/* {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' ? */}

                    <Menu.Item key="discrepancyReport">
                      <NavLink activeClassName="active" to="/discrepancy-report">
                        <span>Discrepancy Report</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="moduleReport">
                      <NavLink activeClassName="active" to="/module-report">
                        <span>Module Completeness Report</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="expectedDeliveryReport">
                      <NavLink activeClassName="active" to="/expected-delivery-report">
                        <span>Expected Delivery Report</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="receivedReport">
                      <NavLink activeClassName="active" to="/order-completeness">
                        <span> Order Completeness Report</span>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="BOMqtyReport">
                      <NavLink activeClassName="active" to="/BOMqty-vsInventory">
                        <span>BOM Quantities vs. Inventory Report</span>
                      </NavLink>
                    </Menu.Item>

                    {/* : null} */}

                  </SubMenu>
                }

                <Menu.Item key="siteMgmt" icon={<RiSettings5Line />}>
                  <NavLink
                    activeClassName="active"
                    to="/site-selection"
                    onClick={() => {
                      setSidebar("tier2");
                      dispatch(getUserTier('tier2'));
                      //localStorage.setItem("sidebartier", JSON.stringify('tier2'));
                    }}
                  >
                    <span>SITE MANAGEMENT</span>
                  </NavLink>
                </Menu.Item>



                {/* {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' ? */}
                {authUser.tier_id.tier_type != 'tier2' ?
                  <Menu.Item key="admin" icon={<RiAdminLine />}>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard"
                      onClick={() => {
                        setSidebar("tier1");
                        dispatch(getUserTier('tier1'));
                        //localStorage.setItem("sidebartier", 'tier1');
                      }}
                    >

                      <span>Back to Admin</span>{/* set tier1 */}
                    </NavLink>
                  </Menu.Item> : null
                }

              </Menu>
            </>
          )}

        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

