import { lazy } from 'react';

const routes = [
    {
        path: `/`,
        component: lazy(() => import("../components/dashboard/dashboard")),
        exact: true,
        //breadcrumb: ' > DashBoard'
    },
    {
        path: `/dashboard`,
        component: lazy(() => import("../components/dashboard/dashboard")),
        exact: true,
        breadcrumb: ' Dashboard'
    },
    //user
    {
        path: `/usersList`,
        component: lazy(() => import("../components/Users/UsersList")),
        exact: true,
        breadcrumb: ' User List'
    },
    {
        path: `/usersList/create-user`,
        component: lazy(() => import("../components/Users/CreateUser")),
        exact: true,
        breadcrumb: ' > Create User'
    },
    {
        path: `/usersList/edit-user/:id`,
        component: lazy(() => import("../components/Users/EditUser")),
        exact: true,
        breadcrumb: ' > Edit User',

    },
    {
        path: `/usersList/userProfile/:id`,
        component: lazy(() => import("../components/Users/UserProfileComponent")),
        exact: true,
        breadcrumb: ' > User Profile'
    },
    {
        path: `/AdminProfile2/:id`,
        component: lazy(() => import("../components/Users/EditTier2Profile")),
        exact: true,
        breadcrumb: 'Admin Profile'
    },

   
    //admin
    {
        path: `/AdminProfile/:id`,
        component: lazy(() => import("../components/Users/UserProfileComponent")),
        exact: true,
        breadcrumb: ' Admin Profile'
    },
    {
        path: `/AdminProfile/edit-user/:id`,
        component: lazy(() => import("../components/Users/EditUser")),
        exact: true,
        breadcrumb: ' Edit Admin Profile'
    },
    {
        path: `/changePassword`,
        component: lazy(() => import("../components/ChangePassword/ChangePassword")),
        exact: true,
        breadcrumb: ' Change Password'
    },
    {
        path: `/changeUserPassword/:id`,
        component: lazy(() => import("../components/ChangePassword/changeOtherProfilePwd")),
        exact: true,
        breadcrumb: ' Change Password'
    },
    //permission
    {
        path: `/permissionList`,
        component: lazy(() => import("../components/Permissions/PermissionsList")),
        exact: true,
        breadcrumb: ' Permission Templates',
        // matchOptions: {
        //     exact: true,
        //     strict: false,
        //     sensitive: true,
        // },
    },
    {
        path: `/permissionList/create-permission`,
        component: lazy(() => import("../components/Permissions/CreatePermission")),
        exact: true,
        breadcrumb: ' > Create Permission Template'
    },
    {
        path: `/permissionList/edit-permission/:id`,
        component: lazy(() => import("../components/Permissions/EditPermission")),
        exact: true,
        breadcrumb: ' > Edit Permission Template'
    },
    {
        path: `/permissionList/display/:id`,
        component: lazy(() => import("../components/Permissions/DisplayPermission")),
        exact: true,
        breadcrumb: ' > Display Permission Template',
    },

    //site
    {
        path: `/sitesList`,
        component: lazy(() => import("../components/Sites/siteList")),
        exact: true,
        breadcrumb: ' Site List '
    },
    {
        path: `/sitesList/create-site`,
        component: lazy(() => import("../components/Sites/createSite")),
        exact: true,
        breadcrumb: ' > Create Site'
    },
    {
        path: `/sitesList/edit-site/:id`,
        component: lazy(() => import("../components/Sites/editSite")),
        exact: true,
        breadcrumb: ' > Edit Site'
    },
    {
        path: `/sitesList/siteDetails/:id`,
        component: lazy(() => import("../components/Sites/siteDetails")),
        exact: true,
        breadcrumb: ' > Site Details'
    },
    {
        path: `/sitesList/archived-sites`,
        component: lazy(() => import("../components/Sites/archivedSites")),
        exact: true,
        breadcrumb: ' > Archived Sites'
    },
    {
        path: `/sitesList/deleted-sites`,
        component: lazy(() => import("../components/Sites/deletedSites")),
        exact: true,
        breadcrumb: ' > Deleted Sites'
    },
    {
        path: `/sitesList/track-changes`,
        component: lazy(() => import("../components/Sites/TrackChanges")),
        exact: true,
        breadcrumb: ' > Track Changes'
    },
    //site type
    {
        path: `/siteTypeList`,
        component: lazy(() => import("../components/Sites/Site-Type/SiteTypeList")),
        exact: true,
        breadcrumb: ' Site Type List'
    },
    {
        path: `/siteTypeList/createSiteType`,
        component: lazy(() => import("../components/Sites/Site-Type/CreateSiteType")),
        exact: true,
        breadcrumb: ' > Create Site Type'
    },
    {
        path: `/siteTypeList/editSiteType/:id`,
        component: lazy(() => import("../components/Sites/Site-Type/EditSiteType")),
        exact: true,
        breadcrumb: ' > Edit Site Type'
    },
    //managing company
    {
        path: `/managingComapanyList`,
        component: lazy(() => import("../components/ManagingCompany/ManagingCompanyList")),
        exact: true,
        breadcrumb: ' Managing Companies'
    },
    {
        path: `/managingComapanyList/addCompany`,
        component: lazy(() => import("../components/ManagingCompany/AddCompany")),
        exact: true,
        breadcrumb: ' > Add Managing Company'
    },
    {
        path: `/managingComapanyList/editCompany/:id`,
        component: lazy(() => import("../components/ManagingCompany/EditCompanyName")),
        exact: true,
        breadcrumb: ' > Edit Managing Company'
    },
    {
        path: `/managingComapanyList/AssociatedUsers/:id`,
        component: lazy(() => import("../components/ManagingCompany/AssociatedUserDetails")),
        exact: true,
        breadcrumb: ' > Associated User(s)'
    },
    //pre-arrival site team
    {
        path: `/sitesList/siteTeamList`,
        component: lazy(() => import("../components/Sites/PreArrival/setUpList")),
        exact: true,
        breadcrumb: ' > Sites Team List'
    },
    {
        path: `/sitesList/siteTeamList/createSiteTeam`,
        component: lazy(() => import("../components/Sites/PreArrival/setUpCreateSite")),
        exact: true,
        breadcrumb: ' > Create Site Team'
    },
    {
        path: `/sitesList/siteTeamList/editSiteTeam/:id`,
        component: lazy(() => import("../components/Sites/PreArrival/editPreArrival")),
        exact: true,
        breadcrumb: ' > Edit Site Team'
    },
    //tier2 site mgmt
    {
        path: `/site-selection`,
        component: lazy(() => import("../components/dashboard/siteSelection")),
        exact: true,
        breadcrumb: ' Site Selection'
    },
    {
        path: `/site-management`,
        component: lazy(() => import("../components/SiteManagement/siteManagementDashboard")),
        exact: true,
        breadcrumb: ' Dashboard'
    },
    {
        path: `/close-site`,
        component: lazy(() => import("../components/SiteManagement/closeSites")),
        exact: true,
        breadcrumb: ' Close Site'
    },
    {
        path: `/close-site/closedSites`,
        component: lazy(() => import("../components/SiteManagement/ArchivedCloseSiteList")),
        exact: true,
        breadcrumb: ' > Closed Sites List'
    },
    {
        path: `/site-inventory-report`,
        component: lazy(() => import("../components/SiteManagement/siteInventoryReport")),
        exact: true,
        breadcrumb: 'Reports'
    },
    //storage
    //Storage Area
    {
        path: `/manage-storageArea`,
        component: lazy(() => import("../components/Storage/StorageArea/manageStorageArea")),
        exact: true,
        breadcrumb: ' Manage Storage Area'
    },
    {
        path: `/manage-storageArea/create-storageArea`,
        component: lazy(() => import("../components/Storage/StorageArea/setUpStorageArea")),
        exact: true,
        breadcrumb: ' > Add Storage Area'
    },
    {
        path: `/manage-storageArea/edit-storageArea/:id`,
        component: lazy(() => import("../components/Storage/StorageArea/editstoragearea")),
        exact: true,
        breadcrumb: ' > Edit Storage Area '
    },
    {
        path: `/manage-storageArea/archived-storageArea`,
        component: lazy(() => import("../components/Storage/StorageArea/archiveStorage")),
        exact: true,
        breadcrumb: ' > Archived Storage Area'
    },
    //Storage Area Type
    {
        path: `/storageArea-type-information`,
        component: lazy(() => import("../components/Storage/StorageAreaType/storageAreaTypeInfo")),
        exact: true,
        breadcrumb: ' Storage Area Type List'
    },
    {
        path: `/storageArea-type-information/add-storageArea-type`,
        component: lazy(() => import("../components/Storage/StorageAreaType/addStorageAreaType")),
        exact: true,
        breadcrumb: ' > Add Storage Area Type'
    },
    {
        path: `/storageArea-type-information/edit-storageArea-type/:id`,
        component: lazy(() => import("../components/Storage/StorageAreaType/editStorageAreaType")),
        exact: true,
        breadcrumb: ' > Edit Storage Area Type'
    },
    //storage unit
    {
        path: `/storageUnitList`,
        component: lazy(() => import("../components/Storage/StorageUnit/StorageUnitList")),
        exact: true,
        breadcrumb: ' Storage Unit Information'
    },
    {
        path: `/storageUnitList/createStorageUnit`,
        component: lazy(() => import("../components/Storage/StorageUnit/createStorageUnit")),
        exact: true,
        breadcrumb: ' > Create Storage Unit'
    },
    {
        path: `/storageUnitList/createStorageUnit/newStorageUnitDisplay`,
        component: lazy(() => import("../components/Storage/StorageUnit/NewUnitDisplay")),
        exact: true,
        breadcrumb: ' > New Storage Unit'
    },
    {
        path: `/storageUnitList/editStorageUnit/:id`,
        component: lazy(() => import("../components/Storage/StorageUnit/EditStorageUnit")),
        exact: true,
        breadcrumb: ' > Edit Storage Unit'
    },
    {
        path: `/storageUnitList/archiveStorageUnitList`,
        component: lazy(() => import("../components/Storage/StorageUnit/archiveStorageUnitList")),
        exact: true,
        breadcrumb: ' > Archived Storage Unit List'
    },

   
    //floor collection
    {
        path:`/storageArea-type-information/Floor-collection-List`,
        component: lazy(() => import("../components/Storage/FloorCollection/FloorCollectionList")),
        exact: true,
        breadcrumb: ' > Floor Collection List'
    },
    {
        path:`/storageArea-type-information/Floor-collection-List/create-Floor-collection`,
        component: lazy(() => import("../components/Storage/FloorCollection/CreateFloorCollection")),
        exact: true,
        breadcrumb: ' > Create Floor Collection'
    },
    {
        path:`/storageArea-type-information/Floor-collection-List/edit-Floor-collection/:id`,
        component: lazy(() => import("../components/Storage/FloorCollection/EditFloorCollection")),
        exact: true,
        breadcrumb: ' > Edit Floor Collection'
    },
    //BOM
   
    {
        path:`/BOM-management`,
        component: lazy(() => import("../components/BOM/BomManagement")),
        exact: true,
        breadcrumb: ' Bill Of Materials '
    },
    {
        path:`/BOM-management/BOM-upload-list`,
        component: lazy(() => import("../components/BOM/bomUploadList")),
        exact: true,
        breadcrumb: ' > BOM Upload List '
    },
    {
        path:`/upload-BOM`,
        component: lazy(() => import("../components/BOM/UploadBOM")),
        exact: true,
        breadcrumb: ' Upload BOM '
    },
    {
        path:`/BOM-management/Add-BOM`,
        component: lazy(() => import("../components/BOM/addNewBOM")),
        exact: true,
        breadcrumb: ' > Add BOM Item'
    },
    {
        path:`/BOM-management/Edit-BOM/:id`,
        component: lazy(() => import("../components/BOM/EditBOM")),
        exact: true,
        breadcrumb: ' > Edit BOM'
    },
    {
        path:`/BOM-management/BOM-upload-list/BOMFileDataList`,
        component: lazy(() => import("../components/BOM/BOMFileDataList")),
        exact: true,
        breadcrumb: ' > BOM File Data List '
    }, 
    {
        path:`/archived-BOM`,
        component: lazy(() => import("../components/BOM/ArchiveBOMList")),
        exact: true,
        breadcrumb: ' Archived BOM List '
    },
    {
        path:`/archived-BOM/archived-line-items`,
        component: lazy(() => import("../components/BOM/archivedLineItemsList")),
        exact: true,
        breadcrumb: ' > Archive Line Items '
    },
    {
        path:`/BOM-management/BOM-upload-list/appended-items`,
        component: lazy(() => import("../components/BOM/appendedItemsList")),
        exact: true,
        breadcrumb: ' > Appended Items '
    },
    //PO
    {
        path:`/Upload-PO`,
        component: lazy(() => import("../components/PurchaseOrder/UploadPO")),
        exact: true,
        breadcrumb: ' Upload PO'
    },
    {
        path:`/PO-List`,
        component: lazy(() => import("../components/PurchaseOrder/POmanagementList")),
        exact: true,
        breadcrumb: ' Purchase Order List'
    },
    {
        path:`/PO-List/PO-upload-list`,
        component: lazy(() => import("../components/PurchaseOrder/POUploadedFileList")),
        exact: true,
        breadcrumb: ' > PO Upload List '
    },
    {
        path:`/PO-List/Add-PO`,
        component: lazy(() => import("../components/PurchaseOrder/AddPOItem")),
        exact: true,
        breadcrumb: ' > Add PO Item'
    },
    {
        path:`/PO-List/Edit-PO/:id`,
        component: lazy(() => import("../components/PurchaseOrder/EditPOItem")),
        exact: true,
        breadcrumb: ' > Edit PO Item'
    },
    {
        path:`/PO-List/PO-upload-list/POFileDataList`,
        component: lazy(() => import("../components/PurchaseOrder/POFileDataList")),
        exact: true,
        breadcrumb: ' > PO File Data List '
    }, 
    {
        path:`/PO-List/PO-upload-list/appended-items`,
        component: lazy(() => import("../components/PurchaseOrder/appendedItemList")),
        exact: true,
        breadcrumb: ' > Appended Items '
    },
    {
        path:`/archived-PO`,
        component: lazy(() => import("../components/PurchaseOrder/archivedPOList")),
        exact: true,
        breadcrumb: ' Archived PO List '
    },
    {
        path:`/archived-PO/archived-line-items`,
        component: lazy(() => import("../components/PurchaseOrder/archivedLineItems")),
        exact: true,
        breadcrumb: ' > Archived Line Items '
    },
    //inventory management
    {
        path:`/received-items`,
        component: lazy(() => import("../components/Inventory/ReceiveItems/ReceivedItems")),
        exact: true,
        breadcrumb: ' Receive Item(s) '
    },
    {
        path:`/received-items/receive-actions`,
        component: lazy(() => import("../components/Inventory/ReceiveItems/ReceiveAction")),
        exact: true,
        breadcrumb: ' > Receive Actions '
    },
    {
        path:`/stow-items`,
        component: lazy(() => import("../components/Inventory/Stow Received Items/stowItems")),
        exact: true,
        breadcrumb: ' Stow Items '
    },
    {
        path:`/transfer-items`,
        component: lazy(() => import("../components/Inventory/TransferItems/TransferItems")),
        exact: true,
        breadcrumb: ' Transfer Items '
    },
    {
        path:`/deploy-items`,
        component: lazy(() => import("../components/Inventory/DeployItems/deployItems")),
        exact: true,
        breadcrumb: ' Deploy Items '
    },
    {
        path:`/deploy-items/view-deployed-items`,
        component: lazy(() => import("../components/Inventory/DeployItems/viewDeployedItems")),
        exact: true,
        breadcrumb: ' > View Deployed Items '
    },
    {
        path:`/manage-items`,
        component: lazy(() => import("../components/Inventory/ManageItems/manageItems")),
        exact: true,
        breadcrumb: ' Manage Items '
    },
    //inventory reports
    {
        path:`/all-items-report`,
        component: lazy(() => import("../components/Inventory Reports/AllItemReports")),
        exact: true,
        breadcrumb: ' All Item(s) Report'
    },
    {
        path:`/module-report`,
        component: lazy(() => import("../components/Inventory Reports/ModuleCompleteness")),
        exact: true,
        breadcrumb: ' Module completeness Report'
    },
    {
        path:`/discrepancy-report`,
        component: lazy(() => import("../components/Inventory Reports/DiscrepancyReport")),
        exact: true,
        breadcrumb: ' Discrepancy Report'
    },
    {
        path:`/expected-delivery-report`,
        component: lazy(() => import("../components/Inventory Reports/ExpectedDelivery")),
        exact: true,
        breadcrumb: ' Expected Delivery Report'
    },
    {
        path:`/BOMqty-vsInventory`,
        component: lazy(() => import("../components/Inventory Reports/BOMqty_inventory")),
        exact: true,
        breadcrumb: 'BOM Qty vs. Inventory Report'
    },
    {
        path:`/order-completeness`,
        component: lazy(() => import("../components/Inventory Reports/OrderCompleteness")),
        exact: true,
        breadcrumb: 'Order Completeness Report'
    },
]

export const renderRoutes = routes
//export default routes;