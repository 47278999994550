import React from 'react'
import { Button, Form, Input, message, Row, Col } from "antd";
import Service from '../service/index';
import loginlogo from '../assets/images/login-logo.svg'
import box1 from '../assets/images/box1.png'
import box2 from '../assets/images/box2.png'
import { toast } from 'react-toastify';
import clicktohide from './App/clicktohide';
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';

function ResetPassword(props) {
    // const [msg, setMsg] = useState(false);

    const validator = async (rule, value) => {
        if (rule.field === 'password') {
            if (value && !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,}$/.test(value)) {
                return Promise.reject('Password should be a minimum of 8 Characters including one alfa, one numeric and one special character.');
            } else {
                return Promise.resolve();
            }
        }
        else {
            return Promise.resolve();
        }
    }


    const handleSubmit = async (values) => {
        try {
            const token = props.match?.params?.token || props.computedMatch?.params?.token;

            if (typeof token === 'undefined' || token === null || token === '') {
                // message.error("Reset token not found!");
                toast.error("Reset token not found!")
                clicktohide()
            }
            //console.log(values);
            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.resetPassword,
                body: {
                    // email: values?.email,
                    password: values?.password,
                    emailResetToken: token
                }
            });
            // console.log(response);
            if (response?.data?.status == 200) {
                // localStorage.clear()
                toast.success(response?.data?.message)
                clicktohide()
                props.history.push('/signin')
                // console.log("props.history",props.history);
            }
            else {
                // console.log("props.history",props.history);
                toast.error(response?.data?.message)
                clicktohide()
            }
            // setMsg(true);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-form">
                    <Row gutter={[16, 16]}>
                        <Col md={12} sm={24} xs={24}>
                            <div className="logo-divlogin">
                                <img className="login-logo" alt='login-img' src={loginlogo} />
                            </div>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form
                                className="gx-signin-form gx-form-row0"
                                onFinish={handleSubmit}
                            >
                                {/* <Form.Item
                                    rules={[
                                        { required: true, message: "Please enter valid E-mail!" },
                                    ]}
                                    name="email"

                                >
                                    <Input type="email" placeholder="Email" prefix={<MailOutlined />} />
                                    <MailOutlined />
                                </Form.Item> */}
                                <Form.Item
                                    name="password"
                                    //hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter new password!',
                                        },
                                        { validator: validator }
                                    ]}
                                >
                                    <Input type="password" placeholder="Enter New Password" prefix={<UnlockOutlined />} />
                                    {/* <UnlockOutlined /> */}
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    dependencies={['password']}
                                    //hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    'The two passwords that you entered do not match!'
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="password" placeholder="Confirm Password" prefix={<UnlockOutlined />} />
                                    {/* <UnlockOutlined /> */}
                                </Form.Item>
                                <Form.Item>
                                    <Button type='primary' htmlType="submit" className="mt-2">
                                        Submit
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    {/* {msg ?
                                        <span>
                                            <Link to='/signin'>Your Password has been changed successfully!</Link>
                                        </span> : null
                                    } */}
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="box-login">
                <img src={box1} alt="box1" className="box-1" />
                <img src={box2} alt="box2" className="box-2" />
            </div>
        </div>
    )
}

export default ResetPassword;