import React from 'react';
import { Button, message, Form, Input, Typography, Row, Col } from "antd";
import Service from '../service';
import { Link } from 'react-router-dom';
import loginlogo from '../assets/images/login-logo.svg'
import box1 from '../assets/images/box1.png'
import { toast } from 'react-toastify';
import clicktohide from './App/clicktohide';
import box2 from '../assets/images/box2.png'
import { MailOutlined } from '@ant-design/icons';

const { Title } = Typography;

function ForgotPassword(props) {
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = async (values) => {
        try {
            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.forgotPassword,
                body:
                    values,

            });
            //console.log(response)
            if (response?.data?.status === 200) {
                console.log("200", response?.data);
                // message.success(response?.data?.message);
                toast.success(response?.data?.message)
                clicktohide()
                props.history.push('/signin');
            } else {
                console.log("else", response?.data);
                // message.error(response?.data?.message);
                toast.error(response?.data?.message)
                clicktohide()
            }
        } catch (error) {
            // dispatch(hideAuthLoader());
            console.log(error);
        }

    }

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-form">
                    <Row gutter={[16, 16]}>
                        <Col md={12} sm={24} xs={24}>
                            <div className="logo-divlogin">
                                <img className="login-logo" src={loginlogo} alt='Logo' />
                            </div>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form
                                initialValues={{ remember: true }}
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                className="gx-signin-form gx-form-row0"
                            >
                                <Form.Item>
                                    <Title level={5}>Forgotten Password</Title>
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" prefix={<MailOutlined />} />
                                    {/* <MailOutlined /> */}

                                </Form.Item>
                                <span className="note">Enter your email address above</span>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="mt-2">
                                        Submit
                                    </Button>
                                    {/* <Link to='/signin'> Go Back to Login Page! </Link> */}
                                </Form.Item>
                                <Form.Item className="link-right back">
                                    <Link to="/signin">Back to login</Link>
                                </Form.Item>

                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="box-login">
                <img src={box1} className="box-1" alt="box1" />
                <img src={box2} className="box-2" alt="box2" />
            </div>
        </div>

    )
}

export default ForgotPassword;