import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import AppLocale from "lngProvider";
import { LoadingOutlined } from '@ant-design/icons';
import MainApp from "./MainApp";
import SignIn from "../SignIn";
//import SignUp from "../SignUp";
//import Service from '../../service';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitUrl } from "appRedux/actions/Auth";

import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import SetPassword from "../SetPassword";
import SuspendedAccount from "../SuspendedAlert";

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => {

  const accessToken = localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : null;

  return (
    <Route
      {...rest}
      render={props =>
        authUser && accessToken
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />}
    />
  )
};

const AuthRoute = ({ component: Component, location, authUser, ...rest }) => {
  return (<Route
    {...rest}
    render={props =>
      !authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/dashboard',
          }}
        />}
  />)
};


const App = () => {

  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth); //loader, alertMessage, showMessage,
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  });

  // useEffect(() => {
  //  console.log = () => {};
  // }, [])
  const { loader } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser != null) {
        if (authUser?.tier_id?.tier_type === 'tier1' || authUser?.tier_id?.tier_type === 'systemAdmin' || authUser?.tier_id?.tier_type === 'reader') {
          history.push('/dashboard');
        } else {
          history.push('/site-selection');
        }
      }
      else if (authUser === null) {
        history.push('/signin');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        if (authUser?.tier_id?.tier_type === 'tier1' || authUser?.tier_id?.tier_type === 'systemAdmin' || authUser?.tier_id?.tier_type === 'reader') {
          history.push('/dashboard');
        } else {
          history.push('/site-selection');
        }
      } else {
        history.push(initURL);
      }
    }

  }, [authUser, initURL]);

  const currentAppLocale = AppLocale[locale.locale];
  //sign up-register
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
        />
        {loader ?
          < div className="loader mainloader">
            <LoadingOutlined style={{ fontSize: '50px', color: '#E74A47' }} />
          </div>
          : null
        }
        <Switch>
          <AuthRoute path={`${match.url}signin`} authUser={authUser} location={location}
            component={SignIn} />
          <AuthRoute path={`${match.url}suspendedAccount`} component={SuspendedAccount} />
          <AuthRoute path={`${match.url}forgotpassword`} component={ForgotPassword} />
          <AuthRoute path={`${match.url}reset-password/:token`} component={ResetPassword} />
          <AuthRoute path={`${match.url}set-password/:token`} component={SetPassword} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location}
            component={MainApp} />

        </Switch>
      </IntlProvider>
    </ConfigProvider >
  )
};

export default memo(App);