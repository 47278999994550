import React from "react"; //,{useEffect, useState}
import { useDispatch, useSelector } from "react-redux";
import {  Popover } from "antd";
//import { useHistory } from "react-router-dom";
import { userSignOut } from "../../appRedux/actions/Auth";
import { Link } from 'react-router-dom';
// import user from "assets/images/placeholder.jpg";

const UserProfile = () => {
  //const history = useHistory();
  const dispatch = useDispatch();
  //const [userProfileName,setUserProfileName] = useState();
 
  const  editUserName  = useSelector(( auth ) => auth);
  //console.log(editUserName.auth.userName);

  const { authUser } = useSelector(({ auth }) => auth);
  const first_name = authUser.first_name.charAt(0).toUpperCase() + authUser.first_name.slice(1);
  const last_name = authUser.last_name.charAt(0).toUpperCase() + authUser.last_name.slice(1);
  let userName = first_name + ' ' + last_name;

 if(editUserName?.auth?.userName){
  userName = editUserName.auth.userName;
 }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === 'systemAdmin' ?
      <Link to={`/AdminProfile/${authUser._id}`}><li><b>My Profile</b></li></Link>:<Link to={`/AdminProfile2/${authUser._id}`}><li><b>My Profile</b></li></Link>
      }
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );
  //Rob Farnandies
  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="hover"
      >

        <span className="gx-avatar-name">Welcome {userName}!
          {/* <Avatar src={user} className="gx-size-40 gx-pointer gx-mr-3" alt="" /> */}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
