import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  //USER_NAME,
  USER_STATUS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  PERMISSIONS
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import { message } from "antd";
//import SignIn from "../../containers/SignIn";
import Service from '../../service/index';
//import { Redirect } from "react-router-dom";

import { toast } from 'react-toastify';
import clicktohide from '../../containers/App/clicktohide'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ email, password, name }) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/register', {
      email: email,
      password: password,
      name: name
    }
    ).then(({ data }) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['authorization'] = "Bearer " + data.token.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        console.log("payload: data.error", data.error);
        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({ email, password }) => {
  return async (dispatch) => {
    try {
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.login,
        body: {
          email: email.trim(),
          password: password,
        }
      });
      //console.log(response.data.data);
      if (response?.data?.data?.status === "2") {
        await dispatch({ type: USER_STATUS, payload: response.data.data.status })
      } else {
        if (response.data && response.data.status === 200) {
          localStorage.setItem("accessToken", (response.data.authToken.accessToken));
          localStorage.setItem("refreshToken", (response.data.authToken.refreshToken));
          localStorage.setItem("user_data", JSON.stringify(response.data.data));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: response.data.authToken.accessToken });
          dispatch({ type: USER_DATA, payload: response.data.data });
          dispatch({type : PERMISSIONS, payload: response.data.permission});
          localStorage.setItem("permissions_allowed",JSON.stringify(response.data.permission))
          // const msg = response.data.message;
          // const index = Service.add_message(msg);
          // if (index === -1) {
          //   toast.success(msg)
          //   Service.remove_message(msg)
          //   clicktohide()
          // }
        } else {
          dispatch({ type: FETCH_ERROR, payload: response.data.message });
          const msg = response.data.message;
          const index = Service.add_message(msg);
          if (index === -1) {
            toast.error(msg)
            Service.remove_message(msg)
            clicktohide()
            // message.error(msg).then(() => {
            //   Service.remove_message(msg);
            // });
          }
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    }
  }
};



export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/me',
    ).then(({ data }) => {
      console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {

  return (dispatch) => {
    dispatch({ type: FETCH_START });

    try {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user_data");
      localStorage.removeItem("sidebartier");
      localStorage.removeItem("siteTeamId");
      localStorage.removeItem("siteDetails");
      localStorage.removeItem("siteId");
      localStorage.removeItem("permissions_allowed");

      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: SIGNOUT_USER_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    }

    // axios.post('auth/logout').then(({data}) => {
    //   console.log("log out",data)
    //   if (data.result) {
    //     localStorage.removeItem("accessToken");
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: SIGNOUT_USER_SUCCESS});
    //   } else {
    //     dispatch({type: FETCH_ERROR, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
  }
};

export const getUserName = (username) => {
  return {
    type: 'USER_NAME',
    payload: username
  }
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};