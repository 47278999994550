import React from 'react'
import { Button, Form, Input, message, Row, Col } from "antd";
//import { Link } from 'react-router-dom';
import Service from '../service/index';
import loginlogo from '../assets/images/login-logo.svg'
import box1 from '../assets/images/box1.png'
import box2 from '../assets/images/box2.png'
import { toast } from 'react-toastify';
import clicktohide from './App/clicktohide';
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';

function SetPassword(props) {

    const validator = async (rule, value) => {
        if (rule.field === 'password') {
            if (value && !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,}$/.test(value)) {
                return Promise.reject('Password needs to be at least 8 characters, 1 alphabet, 1 number and 1 special character');
            } else {
                return Promise.resolve();
            }
        }
        else {
            return Promise.resolve();
        }
    }


    const handleSubmit = async (values) => {
        try {
            const token = props.match?.params?.token || props.computedMatch?.params?.token;

            if (typeof token === 'undefined' || token === null || token === '') {
                //  message.error("Reset token not found!");
                toast.error("Reset token not found!")
                clicktohide()
            }
            //console.log(values);
            const response = await Service.makeAPICall({
                methodName: Service.postMethod,
                api_url: Service.setPassword,
                body: {
                    // email: values?.email,
                    password: values?.password,
                    emailResetToken: token
                }
            });
            // if (response?.data) {
            //     console.log(response.data);
            //     console.log(response?.data?.message);
            if (response?.data?.status == 200) {
                // localStorage.clear()
                toast.success(response?.data?.message)
                clicktohide()
                props.history.push('/signin')
            }
            else {
                toast.error(response?.data?.message)
                clicktohide()
            }
            // message.success(response?.data?.message)

            // } 
            // else {
            //     // message.error(response?.data?.message);
            //     toast.error(response?.data?.message)
            //     clicktohide()
            // }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-form">
                    <Row gutter={[16, 16]}>
                        <Col md={12} sm={24} xs={24}>
                            <div className="logo-divlogin">
                                <img className="login-logo" src={loginlogo} alt="logo" />
                            </div>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form
                                className="gx-signin-form gx-form-row0"
                                onFinish={handleSubmit}
                            >
                                {/* <Form.Item
                                    rules={[
                                        { required: true, message: "Please enter valid E-mail!" },
                                    ]}
                                    name="email"

                                >
                                    <Input type="email" placeholder="Email" prefix={<MailOutlined />} />
                                    <MailOutlined />
                                </Form.Item> */}
                                <Form.Item
                                    name="password"
                                    //hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter new password!',
                                        },
                                        { validator: validator }
                                    ]}
                                >
                                    <Input type="password" placeholder="Enter New Password" prefix={<UnlockOutlined />} />
                                    {/* <UnlockOutlined /> */}
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    dependencies={['password']}
                                    //hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    'The two passwords that you entered do not match!'
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="password" placeholder="Confirm Password" prefix={<UnlockOutlined />} />
                                    {/* <UnlockOutlined /> */}
                                </Form.Item>
                                <Form.Item>
                                    <Button type='primary' htmlType="submit" className="mt-2">
                                        Submit
                                    </Button>
                                </Form.Item>
                                <Form.Item>

                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="box-login">
                <img src={box1} className="box-1" alt="box1" />
                <img src={box2} className="box-2" alt="box2" />
            </div>
        </div>
    )
}

export default SetPassword;