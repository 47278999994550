//import { siteById } from "../../../../server/controller/sites";
import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, SITE_BY_ID } from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const getUserTier = (tier) => {
  localStorage.setItem("sidebartier", tier);
  return{
    type:'USER_TIER',
    payload:tier
  }
}

// export const SiteTeamUserIds = (ids) => {
//   return{
//     type:'TEAM_USERIDS',
//     payload:ids
//   }
// }

export const SetSiteIdTier2 = (siteDetails) => {
  localStorage.setItem('siteDetails', JSON.stringify(siteDetails));
  return{
    type: SITE_BY_ID,
    payload : siteDetails
  }
}






