import React from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
    // const [breadcrumb, setBreadcrumb] = useState(props.breadcrumb);
    const breadcrumb = props.breadcrumb;
    const authUser = useSelector(({ auth }) => auth.authUser);
    const siteTeamID = localStorage.getItem("siteTeamId");

    //console.log('id', breadcrumb);
    if (breadcrumb.length <= 1) {
        return null;
    }
    return (
        <div className="breadcrumb crumb">
            {/* {breadcrumb.length > 0 && breadcrumb.map((item, index) =>
                <Link to={item.path} key={index}><span>{item.breadcrumb}</span> </Link>
            )} */}

            {
                breadcrumb.map((item, index) =>
                    (item.path) === '/sitesList/siteTeamList' ?
                        <Link type="button" to={{
                            pathname: '/sitesList/siteTeamList',
                            state: {
                                site_id: siteTeamID
                            }
                        }}><span>{item.breadcrumb}</span></Link>
                        
                        :

                        (item.path.includes('/permissionList') && authUser?.tier_id?.tier_type !== 'systemAdmin') ?  
                        <Link 
                            to={item.path} disabled style={{color:'#696969'}}>
                                {item.breadcrumb}
                            </Link>
                        :
                        <Link to={item.path} key={index} disabled={index === breadcrumb?.length - 1 ? true : false} >
                            {/* <span style={index == breadcrumb?.length - 1 ? { color: "#2a57a5" } : {}}>{item.breadcrumb}</span> */}
                            <span>{item.breadcrumb}</span>
                        </Link>
                )
            }
        </div>
    )
};

export default Breadcrumb;