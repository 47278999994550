import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
// import user from "assets/images/placeholder.jpg";
import { Link } from 'react-router-dom';

const UserInfo = () => {

  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const first_name = authUser.first_name.charAt(0).toUpperCase() + authUser.first_name.slice(1);
  const last_name = authUser.last_name.charAt(0).toUpperCase() + authUser.last_name.slice(1);
  let userName = first_name + ' ' + last_name;

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {authUser.tier_id.tier_type === 'tier1' || authUser.tier_id.tier_type === "systemAdmin" ?
      <Link to={`/AdminProfile/${authUser._id}`}><li><b>My Profile</b></li></Link>:<Link to={`/AdminProfile2/${authUser._id}`}><li><b>My Profile</b></li></Link>
      }
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <>
      
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="hover">
          <span>Welcome {userName}!<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        {/* <Avatar src={user}
              className="gx-avatar gx-pointer" alt=""/> */}
      </Popover>
    </>
  )

}

export default UserInfo;
