import React, { Suspense } from "react";
import { Layout } from "antd";
import { Route, Switch, Redirect } from "react-router-dom";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import { renderRoutes as routes } from '../../routes/routesArray';
import { LoadingOutlined } from '@ant-design/icons';
import Topbar from "../Topbar/index";
import { footerText } from "util/config";
//import App from "routes/index";
import { useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
//import useBreadcrumbs from 'use-react-router-breadcrumbs';

import Breadcrumb from "./Breadcrumbs";

//import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
//import { BrowserRouter as Router } from 'react-router-dom';

const { Content, Footer } = Layout;

const MainApp = (props) => {

  const { navStyle } = useSelector(({ settings }) => settings);
  // const { loader } = useSelector(({ auth }) => auth);
  const { authUser } = useSelector(({ auth }) => auth);
  const arr = ["dashboard", "usersList", "sitesList", "permissionList", "siteTypeList", "managingComapanyList", "AdminProfile"];

  // const match = useRouteMatch();


  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };



  //const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          {/* {loader ?
          < div className="loader">
            <LoadingOutlined style={{ fontSize: '50px', color: '#E74A47' }} />
          </div>
          : null
        } */}
          {/* <App match={match}/> */}
          <Suspense fallback={
            <div className="loader mainloader">
              <LoadingOutlined style={{ fontSize: '50px', color: '#E74A47' }} />
            </div>
          }>
            <div className="gx-main-content-wrapper">
              <Switch>
                {routes?.length > 0 &&
                  routes.map((route, index) => {

                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => {
                          const crumbs = routes.filter(({ path }) => props.match.path.includes(path))
                            .map(({ path, ...rest }) => {
                              return ({ breadcrumb: rest.breadcrumb, path: path })
                            });
                          //console.log('route.path', route.path);
                          return (
                            <>
                              <Breadcrumb breadcrumb={crumbs} />
                              {authUser?.tier_id?.tier_type === 'tier2' ?
                                arr.includes(route.path.split('/')[1]) || route.path === '/' ?
                                  <Redirect path="*" to='/site-management' />
                                  : <route.component {...props} />
                                : 
                                <route.component {...props} />}
                            </>
                          )
                        }}
                      />)
                  })
                }

                {authUser?.tier_id?.tier_type === 'tier1' || authUser?.tier_id?.tier_type === 'systemAdmin' || authUser?.tier_id?.tier_type === 'reader' ?
                  <Redirect path="*" to='/' /> : <Redirect path="*" to='/site-management' />}
              </Switch>
            </div>
          </Suspense>


          <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

