import React, { useState } from 'react';
import { Modal } from 'antd';

const SuspendedAccount = () => {
    const [visible, setVisible] = useState('true')
    return (
        <>
            <Modal
                footer={null}
                visible={visible}
                onCancel={() => setVisible(false)}
            >
                <p style={{ color: 'red' }}>Your Account is Suspended.</p>
            </Modal>
        </>
    )
};

export default SuspendedAccount;