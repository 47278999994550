import { INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, USER_NAME, USER_STATUS,
  ON_HIDE_LOADER, ON_SHOW_LOADER,PERMISSIONS } from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  token: (localStorage.getItem('token')),
  accessToken: (localStorage.getItem('accessToken')),
  initURL: '',
  authUser: localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : null,
  userName:null,
  userStatus:null,
}

export default(state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        token: null,
        authUser: null,
        initURL: '',
        userStatus:null,
        userName:null,
        sidebartier:null,
        siteTeamUserIds:null
      }
    }

    case USER_DATA: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case USER_NAME: {
      //console.log(action.payload)
      return{
        ...state,
        loader: false,
        userName:action.payload
      }
    }

    case USER_STATUS : {
      //console.log(action.payload);
      return{
        ...state,
        loader: false,
        userStatus:action.payload
      }
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case PERMISSIONS : {
      return{
        ...state,
        permissions_allowed: action.payload
      }
    }

    default:
      return state;
  }
}
